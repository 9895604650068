import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/docs-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "troubleshooting"
    }}>{`Troubleshooting`}</h1>
    <p>{`Troubleshooting your nice!nano often falls on to the firmware of choice, but a few directly hardware related items can be addressed.`}</p>
    <h2 {...{
      "id": "my-nicenano-seems-to-be-acting-up-and-i-want-to-re-flash-the-bootloader"
    }}>{`My nice!nano seems to be acting up and I want to re-flash the bootloader`}</h2>
    <h3 {...{
      "id": "i-can-still-get-into-the-existing-bootloader-over-usb"
    }}>{`I can still get into the existing bootloader over USB`}</h3>
    <p>{`In this case you can most likely re-flash the bootloader using `}<a parentName="p" {...{
        "href": "https://github.com/adafruit/Adafruit_nRF52_nrfutil"
      }}><inlineCode parentName="a">{`adafruit-nrfutil`}</inlineCode></a>{`. Here are the steps you'll want to follow:`}</p>
    <ol>
      <li parentName="ol">{`Follow the installation section `}<a parentName="li" {...{
          "href": "https://github.com/adafruit/Adafruit_nRF52_nrfutil#installation"
        }}>{`here`}</a>{`. Most likely you'll just need to run `}<inlineCode parentName="li">{`pip3 install --user adafruit-nrfutil`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Download the `}<a parentName="li" {...{
          "href": "/assets/nice_nano_bootloader-0.6.0_s140_6.1.1.zip"
        }}>{`DFU pkg of the nice!nano bootloader`}</a>{`.`}</li>
      <li parentName="ol">{`Connect your nice!nano and put it into the bootloader using a double-tap reset.`}</li>
      <li parentName="ol">{`Run the following command in your terminal:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`adafruit-nrfutil --verbose dfu serial --package nice_nano_bootloader-0.6.0_s140_6.1.1.zip -p SERIALPORT -b 115200 --singlebank --touch 1200
`}</code></pre>
    <p>{`However, replace `}<inlineCode parentName="p">{`SERIALPORT`}</inlineCode>{` with your respective serial port name on your OS.`}</p>
    <ul>
      <li parentName="ul">{`On Windows it will be in the format of `}<inlineCode parentName="li">{`COM8`}</inlineCode>{`, but the number 8 will depend on what it is in your device manager under serial.`}</li>
      <li parentName="ul">{`On MacOS and Linux it will look something like `}<inlineCode parentName="li">{`/dev/ttyS0`}</inlineCode>{`, but you'll of course need to double check the exact path name.`}</li>
    </ul>
    <p>{`After this runs, you should have your bootloader all re-flashed and fresh.`}</p>
    <h3 {...{
      "id": "i-cant-get-into-the-bootloader-at-all-anymore"
    }}>{`I can't get into the bootloader at all anymore`}</h3>
    <p>{`If you can't get into the bootloader anymore, this will mean you'll need a device programmer. You can select one of the two below and use the steps listed.`}</p>
    <h4 {...{
      "id": "j-link-30"
    }}><a parentName="h4" {...{
        "href": "https://www.amazon.com/Segger-J-Link-EDU-mini-Debugger/dp/B0758XRMTF"
      }}>{`J-Link (~$30)`}</a></h4>
    <ol>
      <li parentName="ol">{`Plug in your nice!nano over USB`}</li>
      <li parentName="ol">{`Connect these 4 pins to the nice!nano (use the `}<a parentName="li" {...{
          "href": "/docs/nice-nano/pinout-schematic"
        }}>{`pinout`}</a>{` as reference)`}
        <ol parentName="li">
          <li parentName="ol">{`VCC/VTref will connect to the VCC pin on the nice!nano`}</li>
          <li parentName="ol">{`GND will connect to any of the GND pins on the nice!nano`}</li>
          <li parentName="ol">{`SWDIO will connect to the SWD pin on the back of the nice!nano`}</li>
          <li parentName="ol">{`SWCLK will connect to the SWC pin on the back of the nice!nano`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Download the `}<a parentName="li" {...{
          "href": "/assets/nice_nano_bootloader-0.6.0_s140_6.1.1.hex"
        }}>{`bootloader hex`}</a></li>
      <li parentName="ol">{`Download `}<a parentName="li" {...{
          "href": "https://www.nordicsemi.com/Software-and-tools/Development-Tools/nRF-Command-Line-Tools/Download#infotabs"
        }}><inlineCode parentName="a">{`nrfjprog`}</inlineCode></a></li>
      <li parentName="ol">{`Run this command in your terminal `}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`nrfjprog -f NRF52 --program nice_nano_bootloader-0.6.0_s140_6.1.1.hex --chiperase
`}</code></pre>
    <h4 {...{
      "id": "st-link-v2-4"
    }}><a parentName="h4" {...{
        "href": "https://www.aliexpress.com/wholesale?trafficChannel=main&d=y&CatId=0&SearchText=st+link+v2&ltype=wholesale&SortType=total_tranpro_desc&groupsort=1&page=1"
      }}>{`ST-Link v2 (~$4)`}</a></h4>
    <ol>
      <li parentName="ol">{`Plug in your nice!nano over USB`}</li>
      <li parentName="ol">{`Connect these 2 pins to the nice!nano (use the `}<a parentName="li" {...{
          "href": "/docs/nice-nano/pinout-schematic"
        }}>{`pinout`}</a>{` as reference)`}
        <ol parentName="li">
          <li parentName="ol">{`SWDIO will connect to the SWD pin on the back of the nice!nano`}</li>
          <li parentName="ol">{`SWCLK will connect to the SWC pin on the back of the nice!nano`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Download the `}<a parentName="li" {...{
          "href": "/assets/nice_nano_bootloader-0.6.0_s140_6.1.1.hex"
        }}>{`bootloader hex`}</a></li>
      <li parentName="ol">{`Download `}<a parentName="li" {...{
          "href": "https://github.com/xpack-dev-tools/openocd-xpack/releases"
        }}><inlineCode parentName="a">{`openocd`}</inlineCode></a></li>
      <li parentName="ol">{`Run this command in your terminal`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`openocd -f interface/stlink.cfg -f target/nrf52.cfg -c "gdb_flash_program enable" -c "gdb_breakpoint_override hard" -c "init" -c "reset halt" -c "flash write_image erase ./nice_nano_bootloader-0.6.0_s140_6.1.1.hex"
`}</code></pre>
    <h2 {...{
      "id": "my-nicenano-wont-connect-to-my-host-device-over-ble"
    }}>{`My nice!nano won't connect to my host device over BLE`}</h2>
    <p>{`Unfortunately there's likely not much you can do from a hardware perspective if you're running into this. This will mostly likely come down to two factors: the firmware you're using and how nicely the host BLE stack works with said firmware.`}</p>
    <p>{`Because there's no simple way for me to offer advice on how to fix this, I would instead ask you to reach out to the respective firmware communities to ask for assistance. In general though, I would first test to see if you can connect to your phone over BLE. This will show whether it's a hardware issue or not. In my experience it's never truly been the nice!nano that has hardware issues, so continued work on the firmware side and OS stack side need to occur.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      